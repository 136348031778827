<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="generateClick"> 生成加工合同</el-button>
        <el-date-picker
          size="small"
          class="vg_ml_16 topDateRangeSearch"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="加工开始日期"
          end-placeholder="加工结束日期"
        />
        <el-button size="small" type="primary" icon="el-icon-search" @click="getStatsList" class="vg_ml_16">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
      </div>
      <search-table
        ref="searchTable"
        :data="tableData"
        :tableRowKey="'oure_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @getTableData="getStatsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { stisAPI } from '@api/modules/stis';
import { outsAPI } from '@api/modules/outs';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/SettleManagement/OureManage/oure';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'OureList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: tableProperties,
      dateRange: [],
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getStatsList();
    },
    getStatsList() {
      this.loadFlag = true;
      let searchForm = this.$refs.searchTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime ? startTime / 1000 : null;
      searchForm.end_time = endTime ? endTime / 1000 : null;
      getNoCatch(stisAPI.getOuins, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.searchTable.resetFields();
      this.getStatsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    generateClick() {
      if (this.multiSelection.length !== 1) return this.$message.warning('请选择一条数据');
      postNoCatch(outsAPI.addOuts, { pinv_part_id: this.multiSelection[0].pinv_part_id }).then(({ data }) => {
        this.$message.success('生成成功');
        this.jump('/outs_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 157,
              form_id: data.form_id
            })
          )
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
