import store from '@/store';
import { strKeep2 } from '@assets/js/StringUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '加工日期', prop: 'pinv_date', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '源单号', prop: 'pinv_no', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '发票号码', prop: 'invoice_nos', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '货号后缀', prop: 'prod_poststfix', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '中文品名', prop: 'prod_hsname', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '中文规格', prop: 'prod_spec', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '数量单位', prop: 'prod_unit', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '数量', prop: 'prod_tnweight', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '单价', prop: 'pinv_part_price', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '金额', prop: 'pinv_part_subtotal', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  {
    label: '已加工数量',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '120px',
    formatter: val => Math.round(val)
  },
  {
    label: '未加工数量',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '120px',
    formatter: val => strKeep2(val - Math.round(val) + '')
  },
  { label: '外销经办人', prop: 'cust_stff_name', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '交货期', prop: 'pinv_date', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  {
    label: '公司抬头',
    prop: 'pinv_cptt_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '120px',
    overflowTooltip: true
  }
];
